'use client';
import React, { createContext, useContext, useEffect, useState } from "react";

interface AppContextType {
    isApp: boolean; 
    locale?: string;
}

const AppContext = createContext<AppContextType | undefined>(undefined); 

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isApp, setIsApp] = useState(false);

    useEffect(() => {
        const platform = new URLSearchParams(window.location.search).get('platform');
    
        if (platform === 'app' || typeof window !== 'undefined' && window.Flutter) {
            setIsApp(true);
        }
    }, []);

    return (
        <AppContext.Provider value={{ isApp }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext); 
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
